@import '../../styles/customMediaQueries.css';

.root {
}

.topbarContent {
  max-width: var(--marketplacePageWidth);
  margin: 0 auto;

  padding: 0 24px;
}

.pageRoot {
  display: flex;
}

/* .SectionHero */
.sectionHero {
  /* Bg */
  background-image: linear-gradient(to left top, #000000, #000000, #320327, #320327);
}

.sectionHeroExperience {
  background-image: linear-gradient(to left top, #000000, #1f1204, #4f2e0c);
}

.heroContent {
  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;
  max-width: var(--marketplacePageWidth);
  width: 100%;
  margin: 0 auto;
  padding: 24px;

  @media (--viewportMedium) {
    height: auto;
    padding: 24px;
  }

  @media (--viewportLarge) {
    padding: 48px 24px;
  }
}

.titleContainer {
  /* Position */
  position: relative;

  /* Layout */
  display: flex;
  flex-direction: column;
}

.title {
  /* Font */
  composes: experiencePageHeroTitle from global;
  color: var(--matterColorLight);

  /* Layout */
  margin: 0;

  /* Limit text */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.textSeparator {
  color: var(--matterColorLight);
  margin: 0 8px;
}

.topContent {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  padding-bottom: 24px;

  @media (--viewportMedium) {
    padding-bottom: 48px;
  }
}

.returnLink {
  /* Font */
  color: var(--matterColorLight);
  font-weight: var(--fontWeightMedium);
  text-decoration: underline;
  text-underline-position: auto;

  /* Layout */
  display: flex;
  align-items: center;
  gap: 12px;

  /* Effect */
  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    &:hover {
      opacity: 0.8;
    }
  }
}

.returnLinkIcon {
  stroke: var(--matterColorLight);
  fill: var(--matterColorLight);
  margin-top: 2px;
}

/* Content */
.contentContainer {
  display: grid;
  grid-template-columns: 1fr;

  max-width: var(--marketplacePageWidth);
  margin: 0 auto 48px;
  padding: 0 24px;

  @media (--viewportLarge) {
    padding: 0;
  }

  @media (--viewportLargeWithPaddings) {
    grid-template-columns: 1fr 380px;
    grid-gap: 48px;

    margin: 0 auto 96px;
    padding: 0 24px;
  }
}

.mainLoadingText {
  margin: 24px;
}

.loadingText {
  margin-top: 0;
  margin-bottom: 0;
}

.mainErrorText {
  /* Font */
  color: var(--failColor);

  margin: 24px;
}

.errorText {
  /* Font */
  color: var(--failColor);

  margin-top: 0;
  margin-bottom: 0;
}

.mainContent {
  margin-top: 48px;
}

.asideContent {
}

/* SectionHosts */
.hostsTitle {
  /* Font */
  composes: h2 from global;
  color: var(--matterColor);
  text-transform: capitalize;

  margin-top: 0;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.hostsByRoles {
  margin-top: 32px;

  &:first-child {
    margin-top: 24px;
  }
}

.roleHeading {
  /* Font */
  color: var(--matterColorSemiLight);

  margin-top: 0;
  margin-bottom: 12px;
}

.hosts {
  /* Layout */
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;

  @media (--viewportMedium) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.noHostsMessage {
  margin-top: 0;
  margin-bottom: 0;
}

.sectionSimilarListings {
  margin-top: 48px;

  /* Aside style */
  background-color: var(--matterColorLight);
  border: 1px solid var(--matterColorNegative);
  border-radius: var(--borderRadiusMedium);
  padding: 24px;
}

.similarListingsHeading {
  /* Font */
  composes: h2 from global;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 24px;
}

.verticalListingCard {
  display: flex;
  align-items: flex-start;

  /* Reset */
  color: var(--matterColor);
  text-decoration: none !important;

  /* Style */
  background: var(--matterColorBright);
  border-radius: var(--borderRadiusMedium);

  &:nth-child(2n) {
    background: var(--matterColorLight);
  }

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  /* Effect */
  transition: var(--transitionStyleButton);
}

.verticalListingCardImageContainer {
  /* Layout */
  position: relative;

  /* Style */
  border-radius: var(--borderRadiusMedium);

  &:after {
    content: '';

    /* Position */
    position: absolute;
    top: 0;
    left: 0;

    /* Size */
    height: 100%;
    width: 100%;

    /* Style */
    background-color: rgba(0, 0, 0, 0.15);
    border-radius: var(--borderRadiusMedium);
    opacity: 0;

    /* Effect */
    transition: opacity 0.15s;
  }

  @media (--viewportMedium) {
    &:hover {
      &:after {
        opacity: 1;
      }
    }
  }
}

.verticalListingCardRootImage {
  height: 86px;
  width: 86px;

  object-fit: cover;
  border-radius: var(--borderRadiusMedium);
}

.verticalListingCardContent {
  padding: 12px;
}

.verticalListingCardTitle {
  margin-top: 0;
  margin-bottom: 0;

  /* Limit text */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.verticalListingCardSubTitle {
  composes: marketplaceTinyFontStyles from global;
  font-size: 14px;
  line-height: 18px;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 0;
}
